import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VeeValidate from "vee-validate";
import zhTW from "vee-validate/dist/locale/zh_TW";
import "bootstrap";
// import VueCookies from "vue-cookies";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import cookie from "./cookie";

import "../src/style/all.scss?=2024120601";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import utils from "@/utils";
import apis from "@/requests/api";
import enums from "@/enums";
import funcs from "@/funcs";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import QRCode from "qrcode";

import i18n from "@/langs/i18n";

import liffs from "@line/liff";

import VConsole from "vconsole";
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  var vConsole = new VConsole();
}

Vue.use(VueSweetalert2, {
  confirmButtonColor: "rgb(22, 65, 143)",
  confirmButtonText: "確定",
  cancelButtonColor: "#727272",
  cancelButtonText: "取消",
  icon: "warning",
});

Vue.prototype.$utils = utils;
Vue.prototype.$api = apis;
Vue.prototype.$cookie = cookie;
Vue.prototype.$enum = enums;
Vue.prototype.$func = funcs;
Vue.prototype.$liff = liffs;

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(QRCode);

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    zhTW,
  },
});

new Vue({
  i18n,
  // VueCookies,
  vConsole,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import { httpPostWithKey } from "@/requests/http";
import { httpPost } from "@/requests/http";

let path = "/checkouts";

export default {
  post(trade) {
    return httpPostWithKey(path, trade);
  },
  postInStore(salTempId, trade) {
    return httpPostWithKey(`${path}/${salTempId}/instore`, trade);
  },
  postTakeOut(trade) {
    return httpPostWithKey(`${path}/takeout`, trade);
  },
  postLinePay(keyid, item) {
    return httpPost(`/${keyid}/linepay/payment`, item);
  },
  paymentCompleted(keyid, salId) {
    return httpPost(`/${keyid}${path}/${salId}/success`);
  },
  paymentConfirming(keyid, salId, item) {
    return httpPost(`/${keyid}/linepay/orders/${salId}/confirm`, item);
  },
};

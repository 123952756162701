const jp = {
  menu: {
    openinghours: "営業時間",
    login: "ログイン",
    joinstore: "会員になる",
    open: "営業中",
    closed: "閉店",
    preorder: "事前注文",
    left: "残り ",
    soldout: "売り切れ",
    cart: "カート",
    cartitem: " 点",
    pleasechooseyourmeal: "メニューをお選びください",
    closedday: "定休日",
    today: "今日",
    stocklimitreached: "在庫上限に達しました",
    closedtoday: "本日は閉店しました",
    ordertimehaspassed: "最後の注文時間を過ぎました",
    thistimeslotisfullybooked: "この時間帯は予約でいっぱいです",
    minuteslater: "分後",
    hourslater: "時間後",
    orderedmeals: "注文済み",
    addmoremeals: "追加注文",
    takeout: "持ち帰り",
    checkmeals: "注文内容を確認する",
    orderedcounts: "追加された品数",
  },
  week: {
    sunday: "日曜日",
    monday: "月曜日",
    tuesday: "火曜日",
    wednesday: "水曜日",
    thursday: "木曜日",
    friday: "金曜日",
    saturday: "土曜日",
  },
  takeAwayTime: {
    expectedpickuptime: "受け取り予定時間",
    pickupdate: "受け取り日",
    pickuptime: "受け取り時間",
    otherdates: "他の日付",
    confirm: "確認",
    today: "今日",
    orderfull: "終了",
    isorderfull: " (この時間帯の注文受付が終了しました)",
  },
  checkout: {
    cart: "カート",
    expectedpickuptime: "受け取り予定時間",
    cancel: "キャンセル",
    notice: "注意事項",
    orderdetails: "注文明細",
    total: "総額",
    memo: "注記",
    member: "会員",
    name: "名前",
    phonenumber: "携帯番号",
    receipt: "領収書",
    uniformnumber: "統一番号",
    vehiclenumber: "バーコード識別番号",
    submitorder: "注文する",
    today: "今日",
    closedtoday: "本日は閉店しました",
    thistimeslotisfullybooked: "この時間帯は予約でいっぱいです",
    ordertimehaspassed: "最後の注文時間を過ぎました",
    allergiesoringredients:
      "アレルギーや入れたくない食材がある場合は、お知らせください。",
    vehiclenumberisincorrect: "バーコード識別番号の形式が正しくありません。",
    areyousuretosubmittheorder: "注文を送信してもよろしいですか？",
    businesshourshavechanged:
      "営業時間が変更されました。もう一度時間を確認してください。",
    areyousureyouwanttodeletethis: "削除してもよろしいですか？",
    areyousureyouwanttodeletetheentireorder:
      "注文全体を削除してもよろしいですか？",
    thedatehasbeenupdated: "日付が更新されました。",
    minuteslater: "分後",
    hourslater: "時間後",
    continueordering: "注文を続ける",
    paymentmethod: "支払い方法",
    payatstore: "店頭支払い",
    paymentconfirming: "支払い確認中",
    paymentcompleted: "決済完了",
    paymentfailed: "決済失敗",
  },
  login: {
    login: "ログイン",
    phonenumber: "携帯番号",
    password: "パスワード",
    forgotpassword: "パスワードを忘れた",
    register: "会員登録",
  },
  join: {
    join: "参加する",
    joinstore: "会員になる",
    alreadyjoin: "すでにログインしています。直接参加できます。",
  },
  forgotPassword: {
    forgotpassword: "パスワードを忘れた",
    phonenumber: "携帯番号",
    getverifycode: "コードを取得する",
    retry: "s 再試行",
    verifycode: "確認コード",
    newpassword: "新しいパスワード",
    enterpasswordagain: "パスワードを再入力",
    pleaseentersamepassword: "同じパスワードを入力してください",
    save: "保存",
    pleasetryagainlater: "後ほど再度お試しください",
    pleaseenterphonenumber: "携帯番号を入力してください",
    pleasecheckyourmobilemessage: "携帯のメッセージを確認してください",
  },
  member: {
    profile: "プロフィール",
    historyorder: "注文履歴",
    changepassword: "パスワード再設定",
    reportissue: "問題報告",
    logout: "ログアウト",
    name: "名前",
    birthday: "誕生日",
    email: "メール",
    address: "住所",
    link: "連携する",
    unlink: "連携を解除する",
    notlink: "未連携",
    save: "保存",
    changesuccess: "変更が成功しました",
    areyousureyouwanttounlink: "連携を解除してもよろしいですか？",
    unlinkseccessful: "連携が解除されました",
  },
  historyOrder: {
    historyorder: "注文履歴",
    ordernumber: "注文番号",
    createtime: "注文時間",
    eattype: "注文タイプ",
    reservetime: "受け取り時間",
    checkouttime: "決済時間",
    memo: "注記",
    total: "総額",
    confirming: "確認中",
    accepted: "受け付けしました",
    rejected: "拒否しました",
    completed: "完了しました",
  },
  order: {
    required: "必須",
    available: "選択可能",
    exceedsselectionlimit: "選択上限を超えました",
    minqtyreached: "最小単位に達しました",
    ok: "はい",
    mealsetsnotselected: "セットメニューが未選択です",
    flavorsnotselected: "味の選択が未完了です",
    mealsetsover: "セットメニューの選択数を超えました",
    suretoreset: "この商品をリセットします。続行してもよろしいですか？",
    modifyflavors: "味を変更",
    save: "保存",
    soldout: "売り切れ",
    memo: "注記",
  },
  trade: {
    orderinformation: "注文情報",
    orderdetail: "注文詳細",
    ordernumber: "注文番号",
    confirming: "確認中",
    accepted: "受け付けしました",
    rejected: "拒否しました",
    completed: "完了しました",
    tablenumber: "テーブル",
    tablenum: "テーブル",
    name: "名前",
    memo: "注記",
    total: "総額",
    receiptinformation: "領収書情報",
    uniformnumber: "統一番号",
    vehiclenumber: "バーコード識別番号",
    backtomenu: "メニューに戻る",
    rejectreason: "拒否理由",
    completetime: "完了時間",
    createtime: "注文時間",
    reservetime: "受け取り時間",
    member: "会員",
    changelogs: "変更履歴",
  },
  changePassword: {
    changepassword: "パスワードを変更",
    oldpassword: "旧パスワード",
    newpassword: "新しいパスワード",
    enterpasswordagain: "パスワードを再入力",
    pleaseentersamepassword: "同じパスワードを入力してください",
    changesuccess: "変更が成功しました",
    save: "保存",
  },
  register: {
    memberregister: "会員登録",
    name: "名前",
    phonenumber: "携帯番号",
    verifycode: "確認コード",
    password: "パスワード",
    enterpasswordagain: "パスワードを再入力",
    pleaseentersamepassword: "同じパスワードを入力してください",
    getverifycode: "コードを取得する",
    retry: "s 再試行",
    register: "登録する",
    alreadyhaveanaccount: "すでにアカウントをお持ちの方",
    pleasetryagainlater: "後ほど再度お試しください",
    pleaseenterphonenumber: "携帯番号を入力してください",
    pleasecheckyourmobilemessage: "携帯のメッセージを確認してください",
  },
  feedback: {
    reportissue: "問題を報告する",
    pleaseentertheissueyouwanttoreport: "報告したい問題を入力してください",
    submit: "送信",
    thanksforyourreport: "ご報告ありがとうございました",
  },
  error: {
    sorrymessage:
      "申し訳ありませんが、お探しのページは存在しないか、削除されました。",
    goback: "前のページに戻る",
    urlexpired: "このURLは無効です。",
  },
};

export default jp;

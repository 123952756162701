<template>
  <div class="invalid-container">
    <img class="invalid-img" src="../imgs/invalid.png" alt="invalid" />
    <div class="invalid-text">{{ $t("checkout.paymentfailed") }}</div>
    <button
      class="btn btn-border flex space-between items-center"
      @click="backToMenu"
    >
      <p>回到點餐首頁</p>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    backToMenu() {
      if (localStorage.getItem("orderFrom") == "inStore") {
        this.goMenu();
        localStorage.removeItem("orderFrom");
      } else if (localStorage.getItem("orderFrom") == "takeout") {
        this.goTakeOutMenu();
        localStorage.removeItem("orderFrom");
      } else if (!localStorage.getItem("orderFrom")) {
        this.goMenu();
      }
    },
    goTakeOutMenu() {
      this.$router.push({
        name: "InStoreTakeOutMenu",
      });
    },
    goMenu() {
      let routeData = this.$func.getRoute();
      this.$router.push({
        name: routeData.InStore + "Menu",
        params: {
          routeid: routeData.RouteId,
        },
      });
    },
  },
  mounted() {
    this.$store.dispatch("updateLoading", false);
  },
};
</script>

<style lang="scss">
.invalid-container {
  width: 100%;
  height: calc(100vh - 99px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .invalid-img {
    width: 180px;
    padding: 20px;
  }
  .invalid-text {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 50px;
  }
}
</style>

<template>
  <div class="login-modal">
    <div class="login-modal-header">
      <div class="login-modal-closed" @click="closed">
        <i class="fas fa-chevron-left"></i>
      </div>
    </div>
    <div class="login-modal-body flex items-center">
      <div v-if="isMember" class="checkout-login-left">
        <div class="input-button-group mb-2 w-100">
          <div class="cancel-area"></div>
          <div class="country-code-container" @click="openCountryList">
            <input
              class="country-code-input"
              aria-hidden="true"
              v-model="currentCountryCode"
            />
            <div class="country-code-text">+{{ currentCountryCode }}</div>
            <i class="fas fa-caret-down"></i>
            <ul class="country-list">
              <li
                v-for="(countryCode, index) in countryCodes"
                :key="index"
                :data-value="countryCode.value"
                class="country"
                :class="[countryCode.value == 886 ? 'active' : '']"
                @click="selectCountry"
              >
                {{ `${countryCode.text}(+${countryCode.value})` }}
              </li>
            </ul>
          </div>
          <input
            class="cellphone-input"
            type="tel"
            v-model="login.Account"
            :placeholder="$t('login.phonenumber')"
            required
          />
        </div>
        <div class="relative input-container mb-2">
          <input
            class="input-1 inline-block m-0"
            :type="passwordType"
            v-model="login.Password"
            :placeholder="$t('login.password')"
            required
          />
          <a class="btn-right" @click="passwordEyeClick" v-if="isPassword">
            <div>
              <i class="fas fa-eye-slash"></i>
            </div>
          </a>
          <a class="btn-right" @click="passwordEyeClick" v-else>
            <div><i class="fas fa-eye"></i></div
          ></a>
        </div>

        <div class="checkout-login-right">
          <button type="btn" class="btn btn-border mb-3" @click="saved">
            {{ $t("login.login") }}
          </button>
          <div class="login-links mb-3">
            <div @click="goForgetPassword" class="bold link">
              {{ $t("login.forgotpassword") }}
            </div>
            <div @click="goRegister" class="bold link">
              {{ $t("login.register") }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="checkout-login-left">
        <div class="checkout-login-right">
          <button type="btn" class="btn btn-border mb-3" @click="joined">
            {{ $t("join.joinstore") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: {},
      passwordType: "password",
      countryCodes: this.$enum.countryCodeType.getAll(),
      currentCountryCode: 886,
    };
  },
  props: ["isMember"],
  computed: {
    isPassword() {
      return this.passwordType == "password";
    },
  },
  watch: {},
  methods: {
    addCancelArea() {
      const cancelArea = document.querySelector(".cancel-area");
      cancelArea.style.display = "block";
      document.addEventListener("click", this.removeCancelArea);
    },
    removeCancelArea() {
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.remove("active");
      cancelArea.style.display = "none";
      document.removeEventListener("click", this.removeCancelArea);
    },
    openCountryList(e) {
      e.stopPropagation();
      const countryList = document.querySelector(".country-list");
      const cancelArea = document.querySelector(".cancel-area");
      countryList.classList.add("active");
      cancelArea.style.display = "block";
      this.addCancelArea();
    },
    selectCountry(e) {
      e.stopPropagation();
      const countries = document.querySelectorAll(".country");
      countries.forEach((c) => {
        c.classList.remove("active");
      });
      this.currentCountryCode = e.target.dataset.value;
      e.target.classList.add("active");
      this.removeCancelArea();
    },
    saved() {
      this.login.CountryCode = this.currentCountryCode;
      var ob = { ...this.login };
      ob.Password = this.$utils.crypto.encrypt(ob.Password);
      // this.$store.dispatch("updateLoading", true);
      this.$emit("saved", ob);
    },
    joined() {
      this.$emit("joined");
    },
    closed() {
      this.$emit("closed");
    },
    goRegister() {
      this.$emit("closed");
      this.$router.push({
        name: "Register",
      });
    },
    goForgetPassword() {
      this.$emit("closed");
      this.$router.push({
        name: "ForgetPassword",
      });
    },
    passwordEyeClick() {
      if (this.isPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
};
</script>
